import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/userPage/Dashboard/Default"))
);
const ManageLeave = Loadable(
  lazy(() => import("views/userPage/Leave/manageLeave"))
);

const RaiseLeave = Loadable(
  lazy(() => import("views/userPage/Leave/raiseLeave"))
);
const RequestBook = Loadable(
  lazy(() => import("views/userPage/Library/RequestBook"))
);
const RequestStatus = Loadable(
  lazy(() => import("views/userPage/Library/RequestStatus"))
);
const ExamPage = Loadable(
  lazy(() => import("views/userPage/ExamPage/ExamPage"))
);
const MainsExamPage = Loadable(
  lazy(() => import("views/userPage/MainsExamPage/MainsExamPage"))
);
// utilities routing
const UtilsTypography = Loadable(
  lazy(() => import("views/utilities/Typography"))
);
const UtilsColor = Loadable(lazy(() => import("views/utilities/Color")));
const UtilsShadow = Loadable(lazy(() => import("views/utilities/Shadow")));
const UtilsMaterialIcons = Loadable(
  lazy(() => import("views/utilities/MaterialIcons"))
);
const UtilsTablerIcons = Loadable(
  lazy(() => import("views/utilities/TablerIcons"))
);
const UnVerifiedStudents = Loadable(
  lazy(() => import("views/pages/VerifyStudent/UnVerifiedStudent"))
);
// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/userDashboard",
  element: <MainLayout />,
  children: [
    {
      path: "/userDashboard",
      element: <DashboardDefault />,
    },
    {
      path: "manageLeave",
      children: [
        {
          path: "manageLeave",
          element: <ManageLeave />,
        },
        {
          path: "raiseLeave",
          element: <RaiseLeave />,
        },
      ],
    },
    {
      path: "MainsexamPage",
      children: [
        {
          path: "examPage/:examIdParams",
          element: <MainsExamPage />,
        },
      ],
    },
    {
      path: "examPage",
      children: [
        {
          path: "examPage/:examIdParams",
          element: <ExamPage />,
        },
      ],
    },
    {
      path: "library",
      children: [
        {
          path: "requestBook",
          element: <RequestBook />,
        },
        {
          path: "requestStatus",
          element: <RequestStatus />,
        },
      ],
    },
    // {
    //     path: 'entranceExam',
    //     children: [
    //         {
    //             path: 'createExam',
    //             element: <CreateExam />
    //         },
    //         {
    //             path: 'allExams',
    //             element: <AllExams />
    //         },
    //         {
    //             path: 'setQuestions',
    //             element: <SetQuestionsForExam />
    //         },
    //         {
    //             path: 'setQuestions/:examIdParams',
    //             element: <SetQuestionsForExam />
    //         },
    //         {
    //             path: 'showQuestions/:examIdParams',
    //             element: <ShowQuestions />
    //         }
    //     ]
    // },
    // {
    //     path: 'regularExam',
    //     children: [
    //         {
    //             path: 'allocateExam',
    //             element: <AllocateExam />
    //         },
    //         {
    //             path: 'createExam',
    //             element: <CreateRegularExam />
    //         },
    //         {
    //             path: 'allExams',
    //             element: <AllRegularExams />
    //         },
    //         {
    //             path: 'setQuestions',
    //             element: <SetQuestionsForRegularExam />
    //         },
    //         {
    //             path: 'setQuestions/:examIdParams',
    //             element: <SetQuestionsForRegularExam />
    //         },
    //         {
    //             path: 'showQuestions/:examIdParams',
    //             element: <ShowQuestionsForRegular />
    //         }
    //     ]
    // },
    // {
    //     path: 'verifyStudent',
    //     children: [
    //         {
    //             path: 'unverifiedStudents',
    //             element: <UnVerifiedStudents />
    //         },

    //     ]
    // },
    // {
    //     path: 'batch',
    //     children: [
    //         {
    //             path: 'createBatch',
    //             element: <CreateBatch />
    //         },
    //         {
    //             path: 'allBatches',
    //             element: <AllBatches />
    //         },
    //         {
    //             path: 'uploadResult',
    //             element: <UploadResult />
    //         },

    //         {
    //             path: 'generateMerit',
    //             element: <GenerateMerit />
    //         }
    //     ]
    // },
    // {
    //     path: 'questionBank',
    //     children: [
    //         {
    //             path: 'createQuestion',
    //             element: <CreateQuestion />
    //         },
    //         {
    //             path: 'allQuestions',
    //             element: <AllQuestions />
    //         },
    //         {
    //             path: 'editQuestion/:questionIdParams',
    //             element: <EditQuestion />
    //         }
    //     ]
    // },
    // {
    //     path: 'admission',
    //     children: [
    //         {
    //             path: 'registerForAdmission',
    //             element: <AuthRegister3 />
    //         }
    //     ]
    // }
  ],
};

export default MainRoutes;
