import dashboard from "./dashboard";
import admin from "./admin";
import faculty from "./faculty.js";
import superAdmin from "./superadmin.js";
import pages from "./pages";
import utilities from "./utilities";
import other from "./other";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard],
  adminIems: [admin],
  superAdmin: [superAdmin],
  faculty: [faculty],
};

export default menuItems;
