import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRoute from './AdminRoute';
import SuperAdminRoute from './SuperAdminRoute';
import FacultyRoutes from './FacultyRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const navigate = useNavigate();
    const userData = JSON.parse(window.localStorage.getItem('User State'));
    console.log(userData);
    const role = userData?.user?.role;
    console.log(role);

    // return useRoutes(role <= 3 ? [AdminRoute, AuthenticationRoutes] : [MainRoutes, AuthenticationRoutes]);
    const routes = [];

    if (role === 1) {
        routes.push(SuperAdminRoute, AuthenticationRoutes);
    } else if (role === 4) {
        routes.push(MainRoutes, AuthenticationRoutes);
    } else if (role === 3) {
        routes.push(FacultyRoutes, AuthenticationRoutes);
    } else if (role === 2) {
        routes.push(AdminRoute, AuthenticationRoutes);
    } else {
        routes.push(AuthenticationRoutes);
    }

    return useRoutes(routes);
}
